import {
  SimpleCategoryResponse,
  SimpleProductResponse,
} from "../services/ResponseModel";

/**
 * Parámetros de negocio
 */
export enum P {
  cached_products = "cached_products",
  cached_categories = "cached_categories",
  slug = "slug",
  name = "name",
  session_token = "session_token",
  user_id = "user_id",
  user_name = "user_name",
}

export const UserRoles = [
  { id: "WORKER", name: "Empleado" },
  { id: "MANAGER", name: "Encargado" },
  { id: "OWNER", name: "Propietario" },
];

export const SeriesEntity = [
  { id: "ORDER", name: "Pedido" },
  { id: "INVOICE", name: "Factura" },
];

export const StaffColors = [
  { value: "#6B8E23", label: "Verde" },
  { value: "#1E90FF", label: "Azul" },
  { value: "#FFD700", label: "Amarillo" },
  { value: "#DF4444", label: "Rojo" },
  { value: "#40E0D0", label: "Turquesa" },
  { value: "#9932CC", label: "Púrpura" },
  { value: "#FF2499", label: "Rosa" },
  { value: "#FF8C00", label: "Naranja" },
  { value: "#4B0082", label: "Azul oscuro" },
];

/** =============================================== **
 **                      Auth                       **
 ** =============================================== **/

export function setSessionToken(
  session_token: string,
  remember: boolean
): void {
  if (remember) localStorage.setItem(P.session_token, session_token);
  sessionStorage.setItem(P.session_token, session_token);
}

export function getSessionToken(): string {
  if (localStorage.getItem(P.session_token) != null)
    return localStorage.getItem(P.session_token) || "";

  if (sessionStorage.getItem(P.session_token) != null)
    return sessionStorage.getItem(P.session_token) || "";

  return "";
}

export function SetUserData(userId: string, userName: string): void {
  sessionStorage.setItem(P.user_id, userId);
  localStorage.setItem(P.user_name, userName);
}

export function getUserId(): string {
  if (sessionStorage.getItem(P.user_id) != null)
    return sessionStorage.getItem(P.user_id) || "";

  return "";
}

export function getAuthHeaders() {
  return { "FDS-Auth": getSessionToken(), "FDS-User-Id": getUserId() };
}

export function LoadBusinessData(name: string, slug: string) {
  localStorage.setItem(P.name, name);
  localStorage.setItem(P.slug, slug);
}

export function UnloadBusinessData() {
  localStorage.removeItem(P.name);
  localStorage.removeItem(P.slug);
}

/**
 * Removes all stored parameters, only those declared in the parameters controller
 */
export function UnloadParameters() {
  for (let key in P) localStorage.removeItem(key);
  sessionStorage.removeItem(P.session_token);
}

/**
 * Returns stored value of a parameter
 * @param p Key of parameter
 * @returns Value of parameter
 */
export function ReadParameter(p: P | string) {
  const val = localStorage.getItem(p);
  return val == null ? "" : val;
}

/** =============================================== **
 **                  Datos cacheados                **
 ** =============================================== **/

export function SaveProductsCache(products: SimpleProductResponse[]) {
  localStorage.setItem(P.cached_products, JSON.stringify(products));
  return;
}

export function ReadProductsCache(): SimpleProductResponse[] {
  if (localStorage.getItem(P.cached_products) == null) return [];

  const val: SimpleProductResponse[] = JSON.parse(
    localStorage.getItem(P.cached_products) || "[]"
  );

  return val;
}

export function SaveCategoriesCache(categories: SimpleCategoryResponse[]) {
  localStorage.setItem(P.cached_categories, JSON.stringify(categories));
  return;
}

export function ReadCategoriesCache(): SimpleCategoryResponse[] {
  if (localStorage.getItem(P.cached_categories) == null) return [];

  const val: SimpleCategoryResponse[] = JSON.parse(
    localStorage.getItem(P.cached_categories) || "[]"
  );

  return val;
}
