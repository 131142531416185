import {
  FetchResult,
  HTTP_R_CODE,
  JSON_HEADERS,
  METHODS,
  RESOURCES,
  URIBuilder,
  securedHeaders,
} from ".";
import { P, ReadParameter } from "../business/ParametersController";

import { LoginResponse } from "./ResponseModel";

export async function TryLogin(
  login: string,
  password: string,
  remember: boolean
) {
  const url = URIBuilder(RESOURCES.auth);

  //>> Response type
  type Response = FetchResult<LoginResponse>;

  const result: Response | null = await fetch(url, {
    method: METHODS.POST,
    headers: JSON_HEADERS,
    body: JSON.stringify({ login: login, password: password }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK)
        throw new Error(JSON.stringify(res.message));

      return res;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });

  return result;
}

export async function UserPinCheck(userId: string, pin: string) {
  const url = URIBuilder(RESOURCES.auth, "user");

  //>> Response type
  type Response = FetchResult<LoginResponse>;

  const result: Response | null = await fetch(url, {
    method: METHODS.POST,
    headers: JSON_HEADERS,
    body: JSON.stringify({ user_id: userId, pin: pin }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK)
        throw new Error(JSON.stringify(res.message));

      return res;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });

  return result;
}

export async function GetPinCode() {
  const url = URIBuilder(
    RESOURCES.restaurants,
    ReadParameter(P.slug),
    RESOURCES.pin
  );

  //>> Response type
  type Response = FetchResult<string>;

  const result: Response | null = await fetch(url, {
    method: METHODS.GET,
    headers: securedHeaders(),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK)
        throw new Error(JSON.stringify(res.message));

      return res;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });

  return result;
}

export async function UpdatePinCode(pin: string | undefined) {
  const url = URIBuilder(
    RESOURCES.restaurants,
    ReadParameter(P.slug),
    RESOURCES.pin
  );

  //>> Response type
  type Response = FetchResult<string>;

  const result: Response | null = await fetch(url, {
    method: METHODS.PUT,
    headers: securedHeaders(),
    body: JSON.stringify({ pin: pin }),
  })
    .then((res) => res.json())
    .then((res: Response) => {
      if (res.status != HTTP_R_CODE.OK)
        throw new Error(JSON.stringify(res.message));

      return res;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });

  return result;
}
